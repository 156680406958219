import { useEffect, useRef } from "react";
// import { effect } from '@preact/signals-react';
import {
  affinityToken,
  appId,
  flowId,
  identifier,
  currentAppBrandingClass,
  hasLoadedSettings,
} from "./store/chatStore";
import ChatBotInput from "./ChatBotInput";
import ChatBotMessageBox from "./ChatBotMessageBox";
import { establishWebsocket } from "./lib/services/sales-services";
import LoadingScreen from "./components/common/LoadingScreen";

//Getting URL params from url
const getUrlParamsValues = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("appId")) {
    appId.value = new URLSearchParams(window.location.search).get("appId")!;
    flowId.value = appId.value;
  }
  if (urlParams.has("affinityToken")) {
    const token = new URLSearchParams(window.location.search).get(
      "affinityToken"
    )!;
    affinityToken.value = token;
  }
  if (urlParams.has("identifier")) {
    const token = new URLSearchParams(window.location.search).get(
      "identifier"
    )!;
    identifier.value = token;
  }
};

export default function ChatBot() {
  //Component initial rendering
  const hasRendered = useRef(false);
  useEffect(() => {
    if (hasRendered.current) return;
    getUrlParamsValues();

    const connectToWebSocket = async () => {
      await establishWebsocket();
    };
    connectToWebSocket();

    hasRendered.current = true;
  }, []);
  return (
    <div>
      {hasLoadedSettings.value === false ? (
        <LoadingScreen />
      ) : (
        <div className={currentAppBrandingClass.value}>
          <div className="ts-chat-bot-wrapper">
            {/* Bot Header */}
            <div className="ts-bot-header">
              <div className="ts-logo">
                <img
                  src={`/vendor/${currentAppBrandingClass}/logo.svg`}
                  alt="Chat App"
                  onError={(e) => {
                    const imgElement = e.target as HTMLImageElement;
                    imgElement.src = "/vendor/ts-logo.svg";
                    imgElement.onerror = null; // To prevent infinite loop in case dummy image also fails to load
                  }}
                />
              </div>
            </div>
            <div className="ts-chat-body-wrapper">
              {/* Bot message box */}
              <ChatBotMessageBox />
              {/* Bot input box */}
              <ChatBotInput />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
