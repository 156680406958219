import { useEffect, useRef, useState } from "react";
import {
  addToConversation,
  conversationState,
  disableInput,
  isBotTyping,
  websocket,
  messageBroker,
  currentAppBrandingClass
} from "./store/chatStore";
import BotTyping from "./components/common/BotTyping";
import { effect } from "@preact/signals-react";
import { SurfaceMessageType, Value } from "./types";

import CustomComponent from "./components/CustomComponent";

export default function ChatBotMessageBox() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = (timeout: number = 200) => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, timeout);
  };
  // Function to remove duplicates based on stepId only for type "text" on page refresh
  const removeDuplicatesTextOnly = (inputArray) => {
    const seen = new Set();
    return inputArray.reduce((accumulator, current) => {
      if (current.type === "text") {
        if (!seen.has(current.stepId)) {
          seen.add(current.stepId);
          accumulator.push(current);
        }
      } else {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  };

  useEffect(() => {
    /**
     * Looping till 2 seconds to make sure all the messages are loaded till then
     * This is to make sure that the scroll to bottom is called after all the messages are loaded
     */
    for (let i = 200; i < 2000; i += 200) {
      scrollToBottom(i);
    }
  }, [containerRef.current?.childNodes.length]);

  // useEffect(() => {
  //   scrollToBottom();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [conversationState.value]);

  effect(() => {
    if (websocket.value) {
      websocket.value.onmessage = async (event) => {
        try {
          let results: any[];
          try {
            results = JSON.parse(event.data);
             console.log(
              "ChatBotMessageBox websocket onmessage event.data: ",
              results
            );
            // Call the function
            if (Array.isArray(results)) {
              //results.pop();
              results = removeDuplicatesTextOnly(results);
            } else {
              results = [results];
            }
            // console.log("Message received component ==>", JSON.stringify(results));
            for (let result of results) {
              let isBot: boolean = true;
              if (result.author === "user") {
                // Parse the content to extract the timestamp
                const contentData = JSON.parse(result.content);
                result = {
                  ...result,
                  ...contentData,
                };
                isBot = false;
              }

              // check if displayOnly is set
              // this would be the case when the previous messages are send to the UI when page is refreshed
              result.displayOnly =
                result.displayOnly == null || result.displayOnly == undefined
                  ? false
                  : result.displayOnly;

              // console.log("Message received component ==>", JSON.stringify(result));
              if (result.closeConversation) {
                disableInput.value = true;
              }

              if (result.pendingStatus && result.pendingStatus === true) {
                isBotTyping.value = true;
              } else {
                isBotTyping.value = false;
              }
              if (result.type === SurfaceMessageType.TEXT) {
                //check if data is list or string
                //check if result.text exists
                if (result?.text) {
                  addToConversation({
                    bot: isBot,
                    element: result.text,
                    displayOnly: result.displayOnly,
                  });
                } else if (result.data && Array.isArray(result.data)) {
                  result.data.forEach((text) => {
                    addToConversation({
                      bot: isBot,
                      element: text,
                      displayOnly: result.displayOnly,
                    });
                  });
                }
              } else if (result.type == SurfaceMessageType.CUSTOM) {
                if (result.text && result.text.length) {
                  addToConversation({
                    bot: isBot,
                    element: result.text,
                    displayOnly: result.displayOnly,
                  });
                }
                const component = result.component;
                const content: Record<string, Value> | undefined = result.data;

                if (!component) {
                  throw new Error("Component not specified");
                }
                if (!CustomComponent) {
                  console.error(
                    `No custom component of type ${component} to handle incoming data - OnMessage`
                  );
                  return;
                }
                const obj = {
                  bot: true,
                  element: (
                    <CustomComponent
                      component={component}
                      data={content}
                      scrollToBottom={scrollToBottom}
                    />
                  ),
                  // TODO refactor this out to proper config
                  isOpenAI: component === "openai" || component === "greetings",
                };
                addToConversation(obj);
              } else {
                messageBroker.value.sendMessage(result);
                if (result.type == SurfaceMessageType.BUTTON) {
                  addToConversation({
                    bot: false,
                    element: result.text,
                    jsonObject: { action: result.data },
                  });
                }
              }
              scrollToBottom();
              // TODO handle button type
            }
          } catch (error) {
            console.log("Message received parse error ==>", error);
          }
        } catch (error) {
          console.log(error);
          addToConversation({
            bot: true,
            element:
              "We’re having trouble connecting to the chat. Please try again later.",
          });

          isBotTyping.value = false;
        }
      };
    }
  });

  return (
    <div ref={containerRef} className="ts-message-box">
      {conversationState.value.map((convo, i) =>
        convo.bot ? (
          <div className="bot" key={i}>
            {/<\/?[a-z][\s\S]*>/i.test(convo.element) ? (
              <div
                className="bot-text-wrapper"
                dangerouslySetInnerHTML={{ __html: convo.element }}
              ></div>
            ) : (
              <div className="bot-text-wrapper">{convo.element}</div>
            )}
          </div>
        ) : (
          <div className="user" key={i}>
            {/<\/?[a-z][\s\S]*>/i.test(convo.element) ? (
              <div
                className="user-text-wrapper"
                dangerouslySetInnerHTML={{ __html: convo.element }}
              ></div>
            ) : (
              <div className="user-text-wrapper">{convo.element}</div>
            )}
          </div>
        )
      )}
      {isBotTyping.value && <BotTyping />}
    </div>
  );
}
