import { ReactElement } from "react";

type InvokeSfApi = {
  contentKey: string; //key to store data fetched from SFDC api
  flowName: string; //name of the SFDC api to call
  flowOutputPropertyName: string; //name of the property that is returned from sfdc api
  inputs: {
    //inputs to pass to SFDC api
    [key: string]: string;
  };
  type: string; //type of SFDC api to call - flow vs apex vs rest
};

type OutComes = {
  [key: string]: {
    nextFlowId?: string;
    //possible outcomes from a user response to this step - often mapped to lex intents
    nextStepId?: string; //id of the next step to fetch from the flow
    context?: {
      followUpStepId: string; //currently used for auth, allows you to store a follow up step id to branch to after nextStepId is fulfilled
    };
    invokeSfApi?: InvokeSfApi; //Details for SFDC api to call
    endOfFlow?: boolean; //signifies the end of the flow
  };
};

type FlowStep = {
  conditionalOutcome?: {
    //field to use if outcome of current step is conditional based on other data
    conditionalField: string; //field to check for conditional outcome
    jsonataExpression: string; //jsonata expression to evaluate that determines next step id
  };
  isWaitingUserInput: string; //true if the step is waiting for user input
  invokeSfApi?: InvokeSfApi; //Details for SFDC api to call
  id: string; //custom id for the step
  nlu: {
    settings?: {
      botId: string; //id of the bot
      botAliasId: string; //alias of the bot
      localeId: string; //locale of the bot
    };
    type: string; //lex, dialogflow, etc
  };
  modalities: {
    transcript: string; //voice transcript
    chat: {
      text: string | string[]; //chat text
      buttons: string[]; //buttons labels to render
      customComponent: {
        type: string;
        content: unknown;
      }; //content type of custom component to render
    };
  };
  cachedData?: {
    dataStore: string; //data store to use - redis, dynamo, etc
    key: string; //key to fetch data
  };
  outcomes: OutComes;
};

export enum SurfaceMessageType {
  TEXT = "text",
  BUTTON = "buttons",
  CUSTOM = "custom",
  OCR_FILE_UPLOAD_REQUEST = "ocrFileUploadRequest",
  FACE_LIVENESS_PARAMETERS = "faceLivenessParameters",
  SLOT_UPDATE = "slotUpdate",
  CONFIRM_AND_PROCEED = "confirmAndProceed",
  FILE_UPLOAD_PARAMETERS = "fileUploadParameters",
  CUSTOM_INTENT_FULFILLED = "customIntentFulfilled",
  SETTINGS = "settings",
}

export type Value =
  | string
  | number
  | boolean
  | null
  | Value[]
  | { [key: string]: Value };

export type SurfaceButton = {
  label: string; //button label
  value?: string; //button value, if omitted uses the label as value
  type?: "prim" | "secon";
};

export type SurfaceMessage = {
  closeConversation?: boolean;
  pendingStatus?: boolean;
  type: SurfaceMessageType;
  text?: string;
  data?: Record<string, Value>;
  component?: string;
  buttons?: SurfaceButton[];
  url?: string;
  intentName?: string;
};

export type { FlowStep, OutComes };

export enum FieldType {
  TEXT = "text",
  PHONE = "phone",
  CURRENCY = "currency",
  VISUAL_PICKER = "visual_picker",
  DROPDOWN = "dropdown",
  RADIO_GROUP = "radio_button_group",
  DATE = "date",
  TIME = "time",
  DATETIME = "datetime",
  LOCATION = "location",
  CHECKBOX_GROUP = "checkbox_group",
  SECTION_HEADING = "section_heading",
  OUTPUT_TEXT = "output_text",
  TEXT_AREA = "textarea",
  RICH_TEXT = "rich_text",
  FILE_UPLOAD = "file_upload",
}

export type FormFieldBase = {
  key: string;
  label?: string;
  type: FieldType;
};

export type FormField =
  | TextAreaInput
  | TextInput
  | DropdownField
  | VisualPickerField
  | CheckboxGroup
  | RadioButton
  | LocationInput
  | PhoneInput
  | DateInput
  | TimeInput
  | DateTimeInput
  | RichTextField
  | FileUpload;

export type FormComponent =
  | FormField
  | OutputText
  | SectionHeading
  | RichTextField;

export type FormComponentSource =
  | FormFieldSource
  | OutputText
  | SectionHeading
  | RichTextField;

export type SectionHeading = {
  type: FieldType.SECTION_HEADING;
  label?: string;
};

export type FormDefinition = {
  title?: string;
  icon?: string;
  iconAltText?: string;
  fields: FormComponent[];
  buttons: Button[];
  echoFormat?: string;
  fileInputLabel?: string;
  ocrKeyMapping?: {
    sampleTextractKeyOne: string;
    sampleTextractKeyTwo: string;
  };
};

export type FormSource = {
  currentFormClass?: string;
  title?: string;
  icon?: string;
  iconAltText?: string;
  fields: FormComponentSource[];
  buttons: Button[];
  echoFormat?: string;
  fileInputLabel?: string;
  isOcrForm?: boolean;
  readOnly?: string | boolean;
};

export type OcrFormSource = FormSource &
  RequireableFieldSource & {
    ocrKeyMapping?: {
      sampleTextractKeyOne: string;
      sampleTextractKeyTwo: string;
    };
    fileInputLabel?: string;
  };

export type FileUpload = FormFieldBase &
  RequireableField & {
    type: FieldType.FILE_UPLOAD;
    label: string;
    multiple?: boolean;
    value?: string;
  };

// export type OcrKeyMapping = {
//   [textractKey: string]: [formFieldKey: string];

// };

export type RequireableFieldSource = {
  required?: boolean | string;
};

export type RequireableField = {
  required?: boolean;
};

export type ValidatableField = {
  validationExpression?: string;
};

export type PrefillableField = {
  value?: string;
};

export type PlaceholdableField = {
  placeholder?: string;
};

export type DropdownFieldSource = FormFieldBase &
  ValidatableField &
  RequireableFieldSource & {
    type: FieldType.DROPDOWN;
    options: Omit<SelectOptionSource, "optionsType" | "options">[] | string;
    value?: string;
    readOnly?: string | boolean;
  };

export type DropdownField = RequireableField &
  PrefillableField &
  FormFieldBase & {
    type: FieldType.DROPDOWN;
    options: SelectOption[];
    readOnly?: string | boolean;
  };

export type TextAreaInputSource = FormFieldBase &
  RequireableFieldSource &
  PrefillableField &
  ValidatableField &
  PlaceholdableField & {
    type: FieldType.TEXT_AREA;
    readOnly?: string | boolean;
  };

export type TextAreaInput = Omit<TextAreaInputSource, "required"> &
  RequireableField;

export type TextInputSource = FormFieldBase &
  RequireableFieldSource &
  PrefillableField &
  ValidatableField &
  PlaceholdableField & {
    type: FieldType.TEXT | FieldType.CURRENCY;
    readOnly?: string | boolean;
  };

export type TextInput = Omit<TextInputSource, "required"> & RequireableField;

export type FormFieldSource =
  | DropdownFieldSource
  | VisualPickerFieldSource
  | CheckboxGroupSource
  | RadioButtonSource
  | LocationInputSource
  | PhoneInputSource
  | DateInputSource
  | TimeInputSource
  | DateTimeInputSource
  | TextInputSource
  | TextAreaInputSource
  | FileUpload;

export type Button = {
  label: string;
  value?: string;
};

export type SubOptionSource = {
  label?: string;
  value: string;
  selected?: string | boolean;
};

export type SubOption = SubOptionSource & {
  selected?: string | boolean;
};

export enum OptionType {
  SINGLE = "single",
  MULTI = "multiple",
}

export type SelectOptionSource = {
  key?: string; // key when child options are provided
  label?: string;
  value: string;
  selected?: string | boolean;
  options?: SubOptionSource[] | string; // only one level of child options supported
  optionsType?: OptionType;
  customizable?: boolean;
  inputPlaceholder?: string;
  readOnly?: string | boolean;
  customValueField?: FormFieldSource;
};

export type SelectOption = {
  key?: string;
  label?: string;
  value: string;
  selected?: string | boolean;
  options?: SubOption[];
  optionsType?: OptionType;
  customizable?: boolean;
  inputPlaceholder?: string;
  readOnly?: string | boolean;
  customValueField?: FormField;
};

export type SelectOptionGroupSource = {
  key: string;
  label?: string;
  options: SelectOptionSource[];
  optionsType?: OptionType;
};

export type SelectOptionGroup = {
  key: string;
  label?: string;
  options: SelectOption[];
  optionsType?: OptionType;
};

// format of data sent back over websocket to server
// message text is the combined keys of top level options and suboptions in a template format

export type LocationInputSource = FormFieldBase & {
  type: FieldType.LOCATION;
  searchLabel: string;
  // map options?
};

// TODO: expand this type
export type LocationInput = LocationInputSource;

export type LocationOutput = {
  location: {
    lat: number;
    lon: number;
  };
  //address: ,
  additionalInfo?: string;
};

export type CheckboxGroupSource = FormFieldBase &
  RequireableFieldSource &
  SelectOptionGroupSource & {
    type: FieldType.CHECKBOX_GROUP;
    value?: string[];
    readOnly?: string | boolean;
  };

export type CheckboxGroup = SelectOptionGroup &
  RequireableField & {
    type: FieldType.CHECKBOX_GROUP;
    value?: string[];
    readOnly?: string | boolean;
  };

export type RadioButtonSource = FormFieldBase &
  PrefillableField &
  RequireableFieldSource &
  SelectOptionGroupSource & {
    type: FieldType.RADIO_GROUP;
    optionsType: OptionType.SINGLE;
    readOnly?: string | boolean;
  };

export type RadioButton = SelectOptionGroup &
  PrefillableField &
  RequireableField & {
    type: FieldType.RADIO_GROUP;
    optionsType: OptionType.SINGLE;
    readOnly?: string | boolean;
  };

// output is one or more "value" objects which include the value and any child options

export type RadioCheckboxValue = {
  value: string; // value of the select option
  options: {
    // represents child option keys
    [key: string]: boolean;
  };
};

export type PhoneInputSource = FormFieldBase &
  ValidatableField &
  PrefillableField &
  RequireableFieldSource &
  PlaceholdableField & {
    type: FieldType.PHONE;
    readOnly?: string | boolean;
  };

export type PhoneInput = PhoneInputSource & RequireableField;

export type OutputText = {
  content: string; // html string to render
  type: FieldType.OUTPUT_TEXT;
};

export type RichTextFieldSource = RequireableFieldSource &
  PrefillableField &
  PlaceholdableField & {
    key: string;
    type: FieldType.RICH_TEXT;
    label?: string;
    content: string;
  };

export type RichTextField = RichTextFieldSource & RequireableField;

export type DateInputSource = FormFieldBase &
  ValidatableField &
  PrefillableField &
  RequireableFieldSource & {
    type: FieldType.DATE;
    max?: string;
    readOnly?: string | boolean;
  };

export type DateInput = DateInputSource & RequireableField;

export type TimeInputSource = FormFieldBase &
  PrefillableField &
  ValidatableField &
  RequireableFieldSource & {
    type: FieldType.TIME;
    max?: string;
    readOnly?: string | boolean;
  };

export type TimeInput = TimeInputSource & RequireableField;

export type DateTimeInputSource = FormFieldBase &
  PrefillableField &
  ValidatableField &
  RequireableFieldSource & {
    type: FieldType.DATETIME;
    max?: string;
  };

export type DateTimeInput = DateTimeInputSource & RequireableField;
// #region Visual Picker

export type VisualPickerFieldSource = FormFieldBase &
  RequireableFieldSource &
  VisualPickerPropsSource & {
    type: FieldType.VISUAL_PICKER;
    readOnly?: string | boolean;
  };

export type VisualPickerField = RequireableField &
  VisualPickerProps & {
    type: FieldType.VISUAL_PICKER;
    readOnly?: string | boolean;
  };

export type VisualPickerOptionSource = {
  image?: string;
  imageAltText?: string;
  title: string;
  description?: string;
  selected?: string | boolean;
  value?: string | string[]; // value identifier for this option
};

export type VisualPickerOption = {
  image?: string;
  imageAltText?: string;
  title: string;
  description?: string;
  selected?: string | boolean;
  value: string;
};

export type VisualPickerPropsSource = {
  label?: string;
  options: VisualPickerOptionSource[];
  key: string;
  value?: string;
  optionsType: OptionType;
};

export type VisualPickerProps = {
  label?: string;
  options: VisualPickerOption[];
  key: string;
  value?: string;
  optionsType: OptionType;
};

// if output is a single value, this is a string
// if output is a multi value, this is a string[] of value identifiers
export type VisualPickerOutput = string | string[];

// #endregion

// this is the type of the data sent back from a form (along with an optional message)
export type FormOutput = {
  [key: string]: string | string[] | boolean | number | Date | Value[];
};

export type ReviewAndConfirmRoadsideProps = {
  data: {
    echoFormat: string;
    title: string;
    name: string;
    phoneNum: string;
    location: string;
    assistanceType: string;
    towDestination: string;
    vehicleImg: string;
    vehicleName: string;
  };
};

export type RoadsideAssistanceConfirmationProps = {
  data: {
    echoFormat: string;
    assistanceType: string;
  };
};

export type ConfirmationFormProps = {
  data: {
    title: string;
    confirmationData: {
      key: string;
      value: string;
      confirmed?: false;
      alias?: string;
    }[];
  };
};

export type DateTimeProps = {
  data: {
    title: string;
    echoFormat?: string;
    value?: string;
  };
};

export type DriverVehicleProps = {
  data: {
    title: string;
    driverList: {
      name: string;
      vehicles: { img: string; title: string; vin: string }[];
    }[];
    echoFormat?: string;
  };
};

export type OtherPartyDriverContactProps = {
  data: {
    title: string;
    echoFormat?: string;
  };
};

export type VehicleInformationProps = {
  data: {
    title: string;
    vehicles: { img: string; title: string; vin: string }[];
    color: string[];
    echoFormat?: string;
  };
};

export type SearchRepairShopProps = {
  data: {
    title?: string;
    echoFormat?: string;
    identityPoolId: string;
    region: string;
    initialCoordinates?: string | number[] | string[] | [number, number];
    indexName: string;
    placeholder: string;
    label?: string;
    towing: boolean | string;
    locationExist: boolean | string;
    initialValue?: string;
    driverHomeAddress: string;
  };
};

export type OtherPartyInformationProps = {
  data: {
    title: string;
    options: string[];
    echoFormat?: string;
  };
};

export type OtherPartyDriverLicenseInsuranceProps = {
  data: {
    title: string;
    subTitle: string;
    type: "insurance" | "license";
    echoFormat?: string;
  };
};

export type LocationOfIncidentProps = {
  data: {
    title?: string;
    states: string[];
    echoFormat?: string;
  };
};

export type LocationProps = {
  data: {
    title?: string;
    echoFormat?: string;
    identityPoolId: string;
    region: string;
    initialCoordinates?: [number, number];
    indexName: string;
    placeholder: string;
    label: string;
    driverHomeAddress: string;
  };
};

export type IncidentDetailsProps = {
  data: {
    title?: string;
    echoFormat?: string;
  };
};

export type InjuryDamageDetailsProps = {
  data: {
    title: string;
    echoFormat?: string;
  };
};

export type AssistanceRequestedProps = {
  data: {
    date: string;
    contact: string;
  };
};

export type ClaimSubmissionStatusProps = {
  data: {
    title: string;
  };
};

export type AssistanceTypeProps = {
  data: {
    echoFormat?: string;
  };
};

export type ReviewAndConfirmDropDownProps = {
  title: string;
  info: {
    key: string;
    value: string;
    icon?: string;
  }[];
  isOpenAccordion?: boolean;
};

export type RoadsideAssistanceContactInfoProps = {
  data: {
    title: string;
    echoFormat?: string;
  };
};

export type ProcessProgressProps = {
  data: {
    title: string;
    claimProgressData: {
      isCompleted: boolean;
      inProgress: boolean;
      value: string;
    }[];
  };
};

export type DamagePartsProps = {
  data: {
    echoFormat?: string;
    DamagedParts: {
      text: string;
      selected?: string | boolean;
    }[];
  };
};

export type ReviewAndConfirmProps = {
  data: {
    title: string;
    subtitle: string;
    reviewData: {
      title: string;
      isOpen: boolean;
      info: {
        key: string;
        value: string;
        icon?: string;
      }[];
      isAccordionOpen: boolean;
    }[];
  };
};

export type TowDestinationProps = {
  data: {
    title: string;
    echoFormat?: string;
  };
};

export type Shop = {
  shopName: string;
  distance: string;
  shopAddress: string;
  shopPhoneNumber: string;
  website?: string;
  hours?: { [key: string]: string };
};

export type SearchResultsProps = {
  data: {
    results: Shop[];
  };
};

export type FloatingButtonsType = {
  primary: string;
  secondary: string;
  cbPrimary?: () => void;
  cbSecondary?: () => void;
  disabled?: boolean;
};

export type CardProps = {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  logo?: string;
};

export type CancelProps = {
  data: {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
  };
};

export type ConfirmationPopUpProps = {
  data: {
    show: boolean;
    headerText: string;
    bodyText: string;
    buttons: {
      label: string;
      value: any;
      class: string;
      addToConversation?: boolean;
      asyncAction?: string;
      buttonAction?: (event) => void;
    }[];
    onClose: () => void;
  };
};

export type FormState = Record<
  string,
  string | string[] | boolean | number | Record<string, string | string[]>
>;

export type SearchRepairShopModalProps = {
  data: {
    show: boolean;
    onClose: () => void;
    towing: boolean | string;
    shopName: string;
    shopAddress: string;
    shopPhoneNumber: string;
  };
};

export interface HeroTileProps {
  data: {
    heroIcon: string;
    heroIconAltText: string;
    titleContent: string; // could include HTML
    subtitleContent: string; // could include HTML
    detailContent: string; // could include HTML
    detailTime: string;
  };
}

export interface MerchantDetailsProps {
  data: {
    title: string;
    merchantName: string;
    merchantPhone: string;
    merchantEmail: string;
    merchantAddress: string;
  };
}

export interface CancelReplaceProps {
  data: {
    title: string;
    image: string;
    lostCardDetailsTitle: string;
    lostCardDetailsNumber: string;
    lostCardDetailsExpiration: string;
    lostCardConditionsTitle: string;
    lostCardConditions1: string;
    lostCardConditions2: string;
    lostCardConditions3: string;
    lostCardConditionsAddress: string;
  };
}

export interface LivenessDetectionProps {
  data: {
    title?: string;
    logo?: string;
    sessionId: string;
    accountId: string;
    identityPoolId: string;
    minConfidenceScore: number;
    minSimilarityScore: number;
    region: string;
    livenessSessionResults?: {
      isLive: boolean;
      confidenceScore: number;
      livenessComplete: boolean;
    };
  };
}

export interface CreditCardUpsellProps {
  data: {
    title: string;
    image: string;
    cardOfferTitle: string;
    cardPrivileges1: string;
    cardPrivileges2: string;
    cardPrivileges3: string;
  };
}

export interface ConfirmPersonalInfoProps {
  data: {
    title: string;
    personInfoNameLabel: string;
    personInfoNameValue: string;
    personInfoDobLabel: string;
    personInfoDobValue: string;
    personInfoSsnLabel: string;
    personInfoSsnValue: string;
    personInfoEmailLabel: string;
    personInfoEmailValue: string;
    personInfoPhoneLabel: string;
    personInfoPhoneValue: string;
  };
}

export interface PaperlessProps {
  data: {
    title: string;
    paperlessText1: string;
    paperlessText2: string;
  };
}

export interface FraudReportConfirmationProps {
  data: {
    title: string;
    recordNumber: string;
    submissionDate: string;
    replacementCard: string;
  };
}

export interface SpendingInsight {
  data: {
    title: string;
    subtitle?: string;
    image: string;
  };
}

export interface CardOfferInsightsTileProps {
  data: {
    title: string;
    subtitle?: string;
    content: string;
    links: Array<{ text: string; url: string }>;
  };
}

export interface VisualButtonsProps {
  echoFormat?: string;
  creditCards: CreditCard[];
  onCardSelected: (cardId: string) => void;
}

export interface CreditCard {
  id: string;
  title: string;
  cardNumber: string;
  cardExpiration: string;
  image: string;
  currentBalance: number;
  label?: string;
  lastFourDigits?: string;
}

export interface AccountSummaryProps {
  onCardSelected?: (cardId: string) => void;
  data: {
    key?: string;
    title?: string;
    cardTitle?: string;
    insightsTitle?: string;
    spendingInsights?: SpendingInsight;
    cardOfferInsights?: CardOfferInsightsTileProps;
    creditCards: CreditCard[];
    echoFormat?: string;
  };
}

export interface CardSelectorPropsSource {
  data: {
    title?: string;
    creditCards: string | CreditCard[];
    echoFormat?: string;
  };
}

export interface TransactionDetailTileProps {
  data: {
    title: string;
    subtitle: string; // date
    map: {
      lat: number;
      long: number;
      description: string;
      address: string;
      mapName: string;
      identityPoolId: string;
      region: string;
      indexName: string;
    };
    tags: {
      icon: ReactElement;
      iconAltText: string;
      text: string;
    }[];
    label: string;
    detailsLabel: string;
    amount: string;

    details: {
      label: string;
      value: string;
    }[];
    button: {
      variant: string;
      label: string;
      selected: boolean;
    };
    referenceNumber: string;
  };
  handleSelectToggle: (tile: any) => void;
}

export interface Tile {
  type: string;
  properties: TransactionDetailTileProps["data"];
}

export interface SelectableTileCarouselProps {
  data: {
    tiles: Tile[];
  };
}

export interface TileCarouselCardProps {
  data: {
    title: string;
    logo: string;
    logoAltText: string;
    tiles: {
      type: string;
      properties: HeroTileProps["data"];
    }[];
    tileDetailLists: {
      title: string;
      items:
        | {
            icon: string;
            iconAltText: string;
            title: string;
            subtitle: string;
            detail: string;
          }[]
        | string;
    }[]; // should match number of tiles
    button: {
      label: string;
      action: string;
    };
    transactions: TransactionItemProps[] | string;
  };
}

export interface TileButtonsProps {
  tileHeading: string;
  tileDetails: string;
  value: string;
}
export interface TileButtonProps {
  data: {
    echoFormat?: string;
    tileButtons: TileButtonsProps[];
  };
}
export type PopupContentProps = {
  data: {
    shopName: string;
    shopAddress: string;
    shopPhoneNumber: string;
    distance: number;
  };
};

export interface StackedButton {
  stackedHeading: string;
  value: string;
}
export interface StackedButtonProps {
  data: {
    echoFormat?: string;
    stackedButtons: StackedButton[];
  };
}
interface Scenario {
  label: string;
  value: string;
}

export interface DetermineScenarioProps {
  data: {
    title: string;
    key: string;
    options: Scenario[];
  };
}

export interface ResponseButtonProps {
  data: {
    buttonClassName?: string;
    buttonTextClassName?: string;
    buttonText: string;
    cbClick?: () => void;
    type?: "button" | "submit" | "reset" | undefined;
    disabled?: boolean;
  };
  disabled?: boolean;
}

export interface ViewTransactionsProps {
  data: {
    title: string;
    transactionHeader: string | TransactionHeaderProps;
    transactionItems: string | TransactionItemProps;
    identityPoolId?: string;
    indexName?: string;
    region?: string;
    mapName?: string;
  };
}
export interface TransactionHeaderProps {
  data: {
    filterHeading: string;
    // transactionFilters: { name: string }[];
    filterModal: FilterModalProps;
  };
  // onApplyFilters: (filters: FilterState) => void;
}

export interface FilterModalProps {
  title: string;
  tabs: {
    heading: string;
    sections: {
      heading: string;
      type: string;
      inputs: {
        selected?: any;
        type: string;
        id: string;
        name: string;
        label: string;
        placeholder?: string;
      }[];
    }[];
  }[];
}
export interface TransactionItemProps {
  echoFormat?: string;
  identityPoolId?: string;
  indexName?: string;
  region?: string;
  mapName?: string;
  data: {
    date: string;
    transactions: {
      name: string;
      category: string;
      amount: string;
      id: string;
      date: string;
      time: string;
      status: string;
      method: string;
      referenceNumber: string;
      merchantType: string;
      address?: string;
      phone?: string;
      latitude?: number;
      longitude?: number;
    }[];
  }[];
}

export type FilterState = {
  status: string[];
  category: string[];
  amount: { fromAmount: string; toAmount: string };
  date: {
    range: string;
    startDate?: string; //can check afterwards
    endDate?: string; //can check afterwards
  };
};

export interface TransactionContextType {
  filters: FilterState;
  updateFilters: (filters: FilterState) => void;
  activeFilters: Array<{ name: string }>;
  removeActiveFilter: (index: number) => void;
}

export interface TransactionGroup {
  date: string;
  transactions: {
    name: string;
    category: string;
    amount: string;
    id: string;
    date: string;
    time: string;
    status: string;
    method: string;
    referenceNumber: string;
    merchantType: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    phone?: string;
  }[];
}
export interface ReviewRecentTransactionsProps {
  data: {
    title: string;
    subtitle: string;
    data: string | TransactionGroup[];
    identityPoolId: string;
    indexName: string;
    initialCoordinates: string | number[] | string[] | [number, number];
    region: string;
    mapName: string;
    confirmedFraudulentTransactions: string | Record<string, string>[];
  };
}

export type AdditionalTransactionalDetailsProps = {
  data: {
    title?: string;
    echoFormat?: string;
    transactionSummaryTitle?: string;
    supportingInfoTitle?: string;
  };
};

export interface ReviewDisputeSummaryProps {
  data: {
    title: string;
    creditCardInfoTitle: string;
    transactionDetailsTitle: string;
    additionalInformationTitle: string;
    transactionDetails:
      | {
          name: string;
          date: string;
          amount: string;
        }[]
      | string;
    additionalInformation: { key: string; value: any }[];
    creditCardInfo: {
      image: string;
      name: string;
      content: string;
      status: string;
    };
    echoFormat?: string;
  };
}
export interface ReviewDetailsDropDownProps {
  title: string;
  info: string;
  isOpenAccordion?: boolean;
}
export interface ReviewDetailsProps {
  data: {
    title: string;
    reviewData: {
      title: string;
      isOpen: boolean;
      info: string;
      isAccordionOpen: boolean;
    }[];
    acknowledgement: string;
  };
}
