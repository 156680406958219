import React, { Suspense } from "react";
// import CreditCardUpsell from "./BankingPlus/CreditCardUpsell";

const quickCard = React.lazy(() => import("./BankingPlusQnA/CardQuickReply"));
const CarouselCards = React.lazy(
  () => import("./BankingPlusQnA/CarouselCards")
);
const LocationCarouselCards = React.lazy(
  () => import("./BankingPlusQnA/LocationCarouselCards")
);
const cardRadioBullet = React.lazy(
  () => import("./BankingPlusQnA/CardRadioBullet")
);
const confirmationCard = React.lazy(
  () => import("./BankingPlusQnA/CardConfirmation")
);
// const Buttons = React.lazy(() => import("./BankingPlusQnA/Buttons"));
const bankingButtons = React.lazy(() => import("./BankingPlusQnA/Buttons"));
const CardRadio = React.lazy(() => import("./BankingPlusQnA/CardRadio"));
const cardList = React.lazy(() => import("./BankingPlusQnA/CardList"));

const multiSelect = React.lazy(() => import("./InsurancePlus/MultiSelect"));
const autoPolicy = React.lazy(() => import("./InsurancePlus/AutoPolicy"));

const healthPlan = React.lazy(() => import("./HealthPlusOld/MedicalPlanUsage"));
const delayedReply = React.lazy(() => import("./FaqAssistant/DelayedReply"));

const openai = React.lazy(() => import("./OpenAI/ResponseCard"));
const buttons = React.lazy(() => import("./Venmo/PromptButtons"));
const greetings = React.lazy(() => import("./common/GreetingsCard"));
const cardSelector = React.lazy(() => import("./BankingPlus/CardSelector"));
const livenessDetector = React.lazy(
  () => import("./BankingPlus/LivenessDetector")
);
// const driverVehicle = React.lazy(() => import("./FNOL/DriverVehicle"));
// const otherPartyInformation = React.lazy(
//   () => import("./FNOL/OtherPartyInformation")
// );
const otherPartyDriverLicenseInsurance = React.lazy(
  () => import("./FNOL/OtherPartyDriverLicense_Insurance")
);
// const otherPartyDriverContact = React.lazy(
//   () => import("./FNOL/OtherPartyDriverContact")
// );
const dateTime = React.lazy(() => import("./FNOL/DateTime"));
const damageParts = React.lazy(() => import("./FNOL/DamageParts"));
const reviewAndConfirm = React.lazy(() => import("./FNOL/ReviewAndConfirm"));
const searchRepairShop = React.lazy(() => import("./FNOL/SearchRepairShop"));
const searchResults = React.lazy(() => import("./FNOL/SearchResults"));
const cancel = React.lazy(() => import("./FNOL/Cancel"));
const confirmationForm = React.lazy(() => import("./common/ConfirmationForm"));
const processProgress = React.lazy(() => import("./common/ProcessProgress"));
const locationOfIncident = React.lazy(
  () => import("./FNOL/LocationOfIncident")
);
// const injuryDamageDetails = React.lazy(
//   () => import("./FNOL/InjuryDamageDetails")
// );
const incidentDetails = React.lazy(() => import("./FNOL/IncidentDetails"));
// const claimSubmissionStatus = React.lazy(
//   () => import("./FNOL/ClaimSubmissionStatus")
// );
const vehicleInformation = React.lazy(
  () => import("./FNOL/VehicleInformation")
);
const location = React.lazy(() => import("./common/LocationPicker"));
const confirmationPopUp = React.lazy(
  () => import("./common/ConfirmationPopUp")
);

const IncidentTypeConfirmation = React.lazy(
  () => import("./InsurancePlus/IncidentTypeConfirmation")
);
// const fnolForm = React.lazy(() => import("./FNOL/FNOLForm"));
// const roadsideAssistanceContactInfo = React.lazy(
//   () => import("./FNOL/RoadsideAssistanceContactInfo")
// );
// const assistanceType = React.lazy(() => import("./FNOL/AssistanceType"));
// const towDestination = React.lazy(() => import("./FNOL/TowDestination"));
const reviewAndConfirmRoadside = React.lazy(
  () => import("./FNOL/ReviewAndConfirmRoadside")
);
const assistanceRequested = React.lazy(
  () => import("./FNOL/AssistanceRequested")
);
const form = React.lazy(() => import("./form/form"));
//const visual_picker = React.lazy(() => import("./form/VisualPicker"));

const searchRepairShopModal = React.lazy(
  () => import("./FNOL/SearchRepairShopModal")
);

const roadsideAssistanceConfirmation = React.lazy(
  () => import("./FNOL/RoadsideAssistanceConfirmation")
);

const heroTile = React.lazy(() => import("./common/HeroTile"));
const tileCarouselCard = React.lazy(() => import("./common/TileCarouselCard"));
const transactionDetailSelectable = React.lazy(
  () => import("./BankingPlus/TransactionDetailSelectableTile")
);

const selectableTileCarousel = React.lazy(
  () => import("./common/SelectableTileCarousel")
);

const accountSummary = React.lazy(() => import("./BankingPlus/AccountSummary"));
const viewTransactions = React.lazy(
  () => import("./BankingPlus/ViewTransactions")
);
const determineScenario = React.lazy(
  () => import("./BankingPlus/DetermineScenario")
);
const responseButton = React.lazy(() => import("./common/ResponseButton"));
const tileButtons = React.lazy(() => import("./common/TileButtons"));
const stackedButtons = React.lazy(() => import("./common/StackedButtons"));
const chargeErrorOfferExit = React.lazy(
  () => import("./BankingPlus/ChargeErrorOfferExit")
);
const fraudReportConfirmation = React.lazy(
  () => import("./BankingPlus/FraudReportConfirmation")
);
const transactionItem = React.lazy(
  () => import("./BankingPlus/TransactionItem")
);
const reviewRecentTransactions = React.lazy(
  () => import("./BankingPlus/ReviewRecentTransactions")
);
const spendingInsightsTile = React.lazy(
  () => import("./BankingPlus/SpendingInsightsTile")
);
const cardOfferInsightsTile = React.lazy(
  () => import("./BankingPlus/CardOfferInsightsTile")
);
const visualButtonsTile = React.lazy(
  () => import("./BankingPlus/VisualButtons")
);
const additionalTransactionalDetails = React.lazy(
  () => import("./BankingPlus/AdditionalTransactionalDetails")
);
const cancelReplaceLostCard = React.lazy(
  () => import("./BankingPlus/CancelReplaceLostCard")
);
const creditCardUpsell = React.lazy(
  () => import("./BankingPlus/CreditCardUpsell")
);
const confirmPersonalInformation = React.lazy(
  () => import("./BankingPlus/ConfirmPersonalInformation")
);
const paperlessOption = React.lazy(
  () => import("./BankingPlus/PaperlessOption")
);
const reviewDisputeSummary = React.lazy(
  () => import("./BankingPlus/ReviewDisputeSummary")
);
const reviewDetails = React.lazy(() => import("./BankingPlus/ReviewDetails"));
const loadingScreen = React.lazy(() => import("./common/LoadingScreen"));
const appointmentSummary = React.lazy(
  () => import("./HealthPlus/AppointmentSummary")
);
const reviewInsuranceContact = React.lazy(
  () => import("./HealthPlus/ReviewInsuranceContact")
);
const selectAppointmentTime = React.lazy(
  () => import("./HealthPlus/SelectAppointmentTime")
);
const selectProvider = React.lazy(() => import("./HealthPlus/SelectProvider"));
const useageSummary = React.lazy(() => import("./UtilityPlusRwc/UsageSummary"));
const analyzeUseage = React.lazy(() => import("./UtilityPlusRwc/AnalyzeUsage"));
const calculatedRates = React.lazy(
  () => import("./UtilityPlusRwc/CalculatedRates")
);
const reduceUseage = React.lazy(() => import("./UtilityPlusRwc/ReduceUseage"));
const viewPayments = React.lazy(() => import("./UtilityPlusRwc/ViewPayments"));
const selectableProviderCarousel = React.lazy(
  () => import("./common/SelectableProviderCarousel")
);
const manageUpcomingAppointment = React.lazy(
  () => import("./HealthPlus/ManageUpcomingAppointment")
);
const appointmentHistory = React.lazy(
  () => import("./HealthPlus/AppointmentHistory")
);
const registeredComponents = {
  quickCard,
  CarouselCards,
  LocationCarouselCards,
  cardRadioBullet,
  confirmationCard,
  // Buttons,
  bankingButtons,
  buttons,
  CardRadio,
  cardList,
  cardSelector,
  multiSelect,
  autoPolicy,

  healthPlan,
  delayedReply,
  livenessDetector,
  openai,
  // button,
  greetings,

  // driverVehicle,
  // otherPartyInformation,
  otherPartyDriverLicenseInsurance,
  // otherPartyDriverContact,
  dateTime,
  damageParts,
  reviewAndConfirm,
  searchRepairShop,
  searchResults,
  cancel,
  confirmationForm,
  processProgress,
  locationOfIncident,
  // injuryDamageDetails,
  incidentDetails,
  // claimSubmissionStatus,
  vehicleInformation,
  location,
  confirmationPopUp,
  IncidentTypeConfirmation,
  // fnolForm,
  // roadsideAssistanceContactInfo,
  // assistanceType,
  // towDestination,
  reviewAndConfirmRoadside,
  assistanceRequested,
  form,
  //visual_picker,
  searchRepairShopModal,
  heroTile,
  tileCarouselCard,
  selectableTileCarousel,
  transactionDetailSelectable,
  roadsideAssistanceConfirmation,
  accountSummary,
  viewTransactions,
  tileButtons,
  determineScenario,
  responseButton,
  stackedButtons,
  chargeErrorOfferExit,
  fraudReportConfirmation,
  transactionItem,
  reviewRecentTransactions,
  spendingInsightsTile,
  cardOfferInsightsTile,
  visualButtonsTile,
  additionalTransactionalDetails,
  cancelReplaceLostCard,
  creditCardUpsell,
  confirmPersonalInformation,
  paperlessOption,
  reviewDisputeSummary,
  reviewDetails,
  loadingScreen,
  appointmentSummary,
  reviewInsuranceContact,
  selectAppointmentTime,
  selectProvider,
  useageSummary,
  analyzeUseage,
  calculatedRates,
  reduceUseage,
  viewPayments,
  selectableProviderCarousel,
  manageUpcomingAppointment,
  appointmentHistory,
};

export default function CustomComponent(props) {
  const CustomComponent = registeredComponents[props.component];
  const content = props.data;
  const scrollToBottom = props.scrollToBottom;

  // console.log("CustomComponent props: ", props);

  return (
    <Suspense>
      <CustomComponent data={content} scrollToBottom={scrollToBottom} />
    </Suspense>
  );
}
