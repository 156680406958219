import "../../css/common/LoadingScreen.scss";

export default function LoadingScreen() {
  return (
    <div className="loading-screen">
      <div className="loading-message">Preparing Your Chat</div>
      <span className="loader"></span>
    </div>
  );
}
